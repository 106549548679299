<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Location</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Location Name "
                    v-model="location.locationName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Model Name'"
                    @blur="$v.location.locationName.$touch()"
                    :is-valid="
                      $v.location.locationName.$dirty
                        ? !$v.location.locationName.$error
                        : null
                    "
                  />
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="saveSpeciality()"
            >
              <CIcon name="cil-check-circle" />Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Location",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },

      locationId: "",
      location: {
        locationId: "0",
        locationName: "",
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    location: {
      locationName: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;

      this.locationId = this.$route.params.locationId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;

      this.locationId = this.$route.params.locationId;
      this.getUser();
    }
  },

  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({ name: "Locations" });
    },

    saveSpeciality() {
      this.$v.location.$touch();
      if (this.$v.location.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.location));
      } else {
        fetch(
          process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/location",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify(this.location),
          }
        ).then((location) => {
          console.log(JSON.stringify(location));

          this.$router.push({ name: "Locations" });
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/location/" +
          this.locationId
      )
        .then((response) => response.json())
        .then((data) => (this.location = data));
    },
  },
  mounted() {},
};
</script>
